import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import breakpoints from '../../util/style/breakpoints'

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "amber.jpg" }) {
          childImageSharp {
            fluid(quality: 85, maxWidth: 660) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#040e18"
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const ContactMePersonalImage = styled(BackgroundSection)`
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;
  ${breakpoints.md`
    margin-top: -6rem;
    width: calc(100% + 0.5rem);
    height: calc(100% + 5rem);
  `};
  ${breakpoints.lg`
    margin-top: -7rem;   
    width: calc(100% + 1rem);
    height: calc(100% + 7rem);
  `};
`

BackgroundSection.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ContactMePersonalImage
