import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../../../util/style/breakpoints'

const Label = styled.label`
  color: white;
  font-family: 'Montserrat';
  font-weight: 300;
  padding-left: 2.2rem;
  position: relative;
  display: block;
  min-height: 2rem;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: solid 2px #393939;
    background: transparent;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #393939;
  }

  /* When the checkbox is checked, add a background */
  input:checked ~ .checkmark {
    border: solid 2px ${props => props.theme['$brand-primary300']};
    background-color: ${props => props.theme['$brand-primary300']};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    ${breakpoints.lg`
      left: 6px;
      top: 1px;
      width: 7px;
      height: 13px;
    `};
  }
`

const InputCheckbox = ({
  children,
  checked,
  name,
  value,
  onChangeCheckbox,
}) => (
  <>
    <Label>
      {children}
      <input
        type="checkbox"
        name={name}
        checked={checked}
        value={value}
        onChange={onChangeCheckbox}
      />
      <span className="checkmark"></span>
    </Label>
  </>
)

export default InputCheckbox
