import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Col, Small } from '@bootstrap-styled/v4'
import Icon from '../Icons'
import breakpoints from '../../util/style/breakpoints'
import { Link } from '../Link'
import { H4, P } from '../Typography'
import { numberWithDots } from '../../util/js/numberWithDots'

const ObjectCol = styled(Col)`
  margin-bottom: 2.5rem;

  ${breakpoints.md`
    margin-bottom: 4.5rem;
  `};
`
const ObjectImg = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
  overflow: hidden;
  max-height: 382px;
  margin-left: -15px;
  margin-right: -15px;
  height: 276px;

  ${breakpoints.md`
    height: 291px;
    margin-left: inherit;
    margin-right: inherit;
  `};

  ${breakpoints.lg`
    height: 384px;
    margin-left: inherit;
    margin-right: inherit;
  `};
`

const Price = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.$gray};
  bottom: 0;
  right: 0;
  p {
    font-weight: 400;
    margin-bottom: 0;
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  span {
    font-size: 1rem;
    font-weight: 300;
  }
`

const ObjectAttribute = styled.span`
  display: flex;
  align-items: center;
  margin-right: 2.225rem;

  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`

const ObjectPreviewCard = ({
  intl,
  url,
  rentOrBuyProperty,
  previewImage,
  transfer,
  division,
  outdoorSpace,
}) => {
  const sanityConfig = {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }
  const previewImageData = getGatsbyImageData(
    previewImage,
    { width: 600 },
    sanityConfig
  )

  return (
    <ObjectCol md={6} className="object-col-md-padding">
      <Link to={url}>
        <div className="mb-3">
          <ObjectImg>
            {previewImage && (
              <GatsbyImage
                alt=""
                image={previewImageData}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
            {(transfer && transfer.availabilty === 'RENTED_OUT') ||
            (transfer && transfer.availabilty === 'SOLD') ? (
              <Price>
                <P>{intl.formatMessage({ id: transfer.availabilty })}</P>
              </Price>
            ) : (
              <>
                {transfer && (transfer.price === 0 || transfer.price) && (
                  <Price>
                    <P>
                      € {numberWithDots(transfer.price)}{' '}
                      {rentOrBuyProperty === 'RENT_PROPERTY' && (
                        <>{intl.formatMessage({ id: 'per_month' })}</>
                      )}
                      {rentOrBuyProperty === 'BUY_PROPERTY' && (
                        <>{intl.formatMessage({ id: 'COST_BUYER' })}</>
                      )}
                    </P>
                  </Price>
                )}
              </>
            )}
          </ObjectImg>
          {transfer && transfer.street && (
            <H4 color="#b98752" className="mb-4">
              {transfer.street}
            </H4>
          )}
          {transfer && transfer.cityArea && transfer.cityArea.city.cityName && (
            <Small color="muted" className="text-uppercase font-weight-bold">
              {transfer.cityArea.city.cityName}
              {transfer.cityArea.cityArea && `, ${transfer.cityArea.cityArea}`}
            </Small>
          )}
        </div>
        <div className="d-none d-md-flex mt-4">
          {division && division.surfaceArea && (
            <ObjectAttribute>
              <Icon width="24" name="livingSpace" />
              <P>{division.surfaceArea} m²</P>
            </ObjectAttribute>
          )}
          {division && division.bedrooms && (
            <ObjectAttribute>
              <Icon width="24" name="bedroom" />
              <P>{division.bedrooms}</P>
            </ObjectAttribute>
          )}
          {division && division.bathrooms && (
            <ObjectAttribute>
              <Icon width="24" name="bathroom" />
              <P>{division.bathrooms}</P>
            </ObjectAttribute>
          )}
          {((outdoorSpace && outdoorSpace.gardenFacilities) ||
            (outdoorSpace && outdoorSpace.balcony)) && (
            <ObjectAttribute>
              <Icon width="24" name="outdoorSpace" />
              <P>
                {outdoorSpace.gardenFacilities &&
                  outdoorSpace.gardenFacilities.length > 0 &&
                  intl.formatMessage({ id: 'GARDEN' })}
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                {outdoorSpace.gardenFacilities &&
                  outdoorSpace.gardenFacilities.length > 0 &&
                  outdoorSpace.balcony &&
                  outdoorSpace.balcony.length > 0 && <>{', '}</>}
                {outdoorSpace.balcony &&
                  outdoorSpace.balcony.length > 0 &&
                  intl.formatMessage({ id: 'BALCONY' })}
              </P>
            </ObjectAttribute>
          )}
        </div>
      </Link>
    </ObjectCol>
  )
}

ObjectPreviewCard.propTypes = {}

export default injectIntl(ObjectPreviewCard)
