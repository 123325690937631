import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  color: white;
  font-family: 'Montserrat';
  font-weight: 300;
  padding-left: 2.2rem;
  position: relative;
  display: block;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: ${props => props.theme['$brand-primary300']} 2px solid;

}

/* On mouse-over, add a grey background color */
&:hover input ~ .checkmark {
  background-color: ${props => props.theme['$brand-primary300']};
}

/* When the radio button is checked, add a blue background */
 input:checked ~ .checkmark {

}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
 .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
	border-radius: 50%;
	background: ${props => props.theme['$brand-primary300']};
}
`

const InputRadio = ({ children, checked, name, value, onChangeRadio }) => {
  return (
    <>
    <Label>
      {children}
      <input type="radio" name={name} checked={checked} value={value} onChange={onChangeRadio}/>
      <span className="checkmark"></span>
    </Label>
  </>
  )
}

export default InputRadio
