import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Row, Col, Form } from '@bootstrap-styled/v4'
import { slide as Drawer } from 'react-burger-menu'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Section from '../components/UI/Section'
import { H1, P } from '../components/Typography'
import ObjectLoadMoreWrapper from '../components/ObjectOverview/ObjectLoadMoreWrapper'
import Input from '../components/UI/Form/Input'
import Icon from '../components/Icons'
import ContactMePersonal from '../components/ContactMePersonal'
import breakpoints from '../util/style/breakpoints'

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}

  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`

const DrawerWrapper = styled.div`
  /* Drawer styles */
  .bm-menu {
    background: #1f1f1f;
    padding: 2.5em 1.5em 0;
  }
  .bm-item-list {
    .bm-item {
      outline: none;
    }
  }
  .bm-burger-button {
    display: none;
  }
  .bm-overlay {
    background: rgba(23, 23, 23, 0.8) !important;
  }
`

const FilterWrapper = styled.div`
  position: relative;
  .close-btn {
    cursor: pointer;
    position: absolute;
    background: #1f1f1f;
    top: -40px;
    right: -24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 48px;
    height: 48px;
  }
  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`

const ButtonDrawer = styled.div`
  width: auto;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2.5rem;
  p {
    margin: 0 0 0 0.75rem;
    color: ${props => props.theme['$brand-primary300']};
  }
`

export const objectOverview = graphql`
  query allObjectsQuery {
    allSanityRealEstateProperty(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          id
          publishedAt
          propertyUrl
          rentOrBuyProperty
          transfer {
            street
            availabilty
            price
            cityArea {
              city {
                cityName
              }
              cityArea
            }
          }
          division {
            surfaceArea
            bathrooms
            bedrooms
          }
          outdoorSpace {
            balcony
            gardenFacilities
          }
          realEstateImages {
            _key
            _rawAsset
          }
        }
      }
    }
  }
`

class ObjectOverview extends React.PureComponent {
  state = {
    isDrawerOpen: false,
    form: {
      bedrooms: undefined,
      surfaceArea: undefined,
      availabilty: {
        AVAILABLE: false,
      },
      gardenFacilities: {
        BACKYARD: false,
      },
      balcony: {
        BALCONY: false,
        BALCONY_ROOF_TERRACE: false,
      },
    },
    filter: {
      transfer: {
        availabilty: [],
        price: {
          min: undefined,
          max: undefined,
        },
      },
      division: {
        bedrooms: {
          min: undefined,
        },
        surfaceArea: {
          min: undefined,
        },
      },
      outdoorSpace: {
        balcony: [],
        gardenFacilities: [],
      },
    },
  }

  handleDrawer() {
    const { isDrawerOpen } = this.state
    this.setState({ isDrawerOpen: !isDrawerOpen })
  }

  isMenuOpen(state) {
    this.setState({ isDrawerOpen: state.isOpen })
  }

  handleRadioChange(changeEvent) {
    const { name, value } = changeEvent.target
    const category = name.substr(0, name.indexOf('-'))
    const characteristic = name.substr(name.indexOf('-') + 1)

    if (!value) {
      const { filter } = this.state
      const newFilter = {
        ...filter,
      }

      newFilter[category][characteristic] = []
      this.setState({ filter: newFilter })
    } else {
      const { filter } = this.state
      const newFilter = {
        ...filter,
      }

      newFilter[category][characteristic] = [value]
      this.setState({ filter: newFilter })
    }
  }

  handleCheckboxChange(changeEvent) {
    const { checked, name, value } = changeEvent.target
    const category = name.substr(0, name.indexOf('-'))
    const characteristic = name.substr(name.indexOf('-') + 1)

    if (!checked) {
      const { filter, form } = this.state
      const newFilter = {
        ...filter,
      }

      const newForm = {
        ...form,
      }

      const removedValueFromArray = newFilter[category][characteristic].filter(
        item => item !== value
      )
      newFilter[category][characteristic] = removedValueFromArray
      newForm[characteristic][value] = false

      this.setState({ filter: newFilter, form: newForm })
    } else {
      const { filter, form } = this.state
      const newFilter = {
        ...filter,
      }

      const newForm = {
        ...form,
      }

      newFilter[category][characteristic].push(value)
      newForm[characteristic][value] = true
      this.setState({ filter: newFilter, form: newForm })
    }
  }

  handleRadioChangeWithMin(changeEvent) {
    const { name, value } = changeEvent.target
    const category = name.substr(0, name.indexOf('-'))
    const characteristic = name.substr(name.indexOf('-') + 1)

    if (!value) {
      const { filter, form } = this.state
      const newFilter = {
        ...filter,
      }

      const newForm = {
        ...form,
      }

      newFilter[category][characteristic].min = undefined
      newForm[characteristic] = undefined

      this.setState({ filter: newFilter, form: newForm })
    } else {
      const { filter, form } = this.state
      const newFilter = {
        ...filter,
      }

      const newForm = {
        ...form,
      }

      newFilter[category][characteristic].min = [value]
      newForm[characteristic] = value
      this.setState({ filter: newFilter, form: newForm })
    }
  }

  handleOptionChange(changeEvent) {
    const { name, value } = changeEvent.target

    const category = 'transfer'
    const characteristic = 'price'

    if (!value) {
      const { filter } = this.state
      const newFilter = {
        ...filter,
      }

      newFilter[category][characteristic][name] = undefined
      this.setState({ filter: newFilter })
    } else {
      const { filter } = this.state
      const newFilter = {
        ...filter,
      }

      newFilter[category][characteristic][name] = value
      this.setState({ filter: newFilter })
    }
  }

  render() {
    const { data, intl } = this.props
    const { filter, form, isDrawerOpen } = this.state
    const buildFilter = filterToBuild => {
      let query = {}
      // Check if filter has properties from transfer
      if (filterToBuild.transfer) {
        query = {
          ...query,
          transfer: {},
        }

        // eslint-disable-next-line
        for (let keys in filterToBuild.transfer) {
          if (
            filterToBuild.transfer[keys].constructor === Object ||
            (filterToBuild.transfer[keys].constructor === Array &&
              filterToBuild.transfer[keys].length > 0)
          ) {
            query.transfer[keys] = filterToBuild.transfer[keys]
          }
        }
      }

      // Check if filter has properties from division
      if (filterToBuild.division) {
        query = {
          ...query,
          division: {},
        }

        // eslint-disable-next-line
        for (let keys in filterToBuild.division) {
          if (
            filterToBuild.division[keys].constructor === Object ||
            (filterToBuild.division[keys].constructor === Array &&
              filterToBuild.division[keys].length > 0)
          ) {
            query.division[keys] = filterToBuild.division[keys]
          }
        }
      }
      if (filterToBuild.outdoorSpace) {
        query = {
          ...query,
          outdoorSpace: {},
        }

        // eslint-disable-next-line
        for (let keys in filterToBuild.outdoorSpace) {
          if (
            filterToBuild.outdoorSpace[keys].constructor === Object ||
            (filterToBuild.outdoorSpace[keys].constructor === Array &&
              filterToBuild.outdoorSpace[keys].length > 0)
          ) {
            query.outdoorSpace[keys] = filterToBuild.outdoorSpace[keys]
          }
        }
      }
      return query
    }

    const filterData = (dataToFilter, query) => {
      const keysWithMinMax = ['price']

      const keysWithMin = ['bedrooms', 'surfaceArea']

      const filteredData = dataToFilter.filter(item => {
        if (query.transfer && item.node.transfer) {
          // eslint-disable-next-line
          for (let key in query.transfer) {
            if (item.node.transfer[key] === undefined) {
              return false
            }
            if (keysWithMinMax.includes(key)) {
              if (
                query.transfer[key]['min'] !== undefined &&
                item.node.transfer[key] < query.transfer[key]['min']
              ) {
                return false
              }
              if (
                query.transfer[key]['max'] !== undefined &&
                item.node.transfer[key] > query.transfer[key]['max']
              ) {
                return false
              }
            } else if (!query.transfer[key].includes(item.node.transfer[key])) {
              return false
            }
          }
        }
        if (query.division && item.node.division) {
          // eslint-disable-next-line
          for (let key in query.division) {
            if (item.node.division[key] === undefined) {
              return false
            }
            if (keysWithMin.includes(key)) {
              if (
                query.division[key]['min'] !== undefined &&
                item.node.division[key] < query.division[key]['min']
              ) {
                return false
              }
            } else if (!query.division[key].includes(item.node.division[key])) {
              return false
            }
          }
        }
        if (
          (query.outdoorSpace && item.node.outdoorSpace) ||
          item.node.outdoorSpace === undefined
        ) {
          // eslint-disable-next-line
          for (let key in query.outdoorSpace) {
            if (item.node.outdoorSpace === undefined) {
              return false
            }
            if (
              !item.node.outdoorSpace[key] ||
              !item.node.outdoorSpace[key].length
            ) {
              return false
            }
            if (
              Array.isArray(item.node.outdoorSpace[key]) &&
              item.node.outdoorSpace[key].length > 0
            ) {
              // eslint-disable-next-line
              for (let outdoorSpace of item.node.outdoorSpace[key]) {
                if (outdoorSpace === undefined) {
                  return false
                }
                if (!query.outdoorSpace[key].includes(outdoorSpace)) {
                  return false
                }
              }
            }
          }
        }
        return true
      })
      return filteredData
    }

    const query = buildFilter(filter)
    const result = filterData(data.allSanityRealEstateProperty.edges, query)

    const priceOptions = (
      <>
        <option value="200">€ 200</option>
        <option value="300">€ 300</option>
        <option value="400">€ 400</option>
        <option value="500">€ 500</option>
        <option value="600">€ 600</option>
        <option value="700">€ 700</option>
        <option value="800">€ 800</option>
        <option value="900">€ 900</option>
        <option value="1000">€ 1000</option>
        <option value="1100">€ 1100</option>
        <option value="1200">€ 1200</option>
        <option value="1300">€ 1300</option>
        <option value="1400">€ 1400</option>
        <option value="1500">€ 1500</option>
        <option value="1750">€ 1750</option>
        <option value="2000">€ 2000</option>
        <option value="2250">€ 2250</option>
        <option value="2500">€ 2500</option>
        <option value="3000">€ 3000</option>
        <option value="4000">€ 4000</option>
        <option value="6000">€ 6000</option>
      </>
    )

    const filterMenu = (
      <>
        <Form className="filter-spacing">
          <P>
            <strong>{intl.formatMessage({ id: 'PRICE' })}</strong>
          </P>
          <Input
            type="dropdown"
            name="min"
            id="price-min"
            value={filter.transfer.price.min}
            label={intl.formatMessage({ id: 'FROM' })}
            onOptionChange={e => this.handleOptionChange(e)}
          >
            <option value="">€ 0</option>
            {priceOptions}
          </Input>
          <Input
            type="dropdown"
            name="max"
            id="price-max"
            value={filter.transfer.price.max}
            label={intl.formatMessage({ id: 'UNTIL' })}
            onOptionChange={e => this.handleOptionChange(e)}
          >
            <option value="">{intl.formatMessage({ id: 'NO_MAX' })}</option>
            {priceOptions}
          </Input>
        </Form>
        <Form className="filter-spacing">
          <P>
            <strong>{intl.formatMessage({ id: 'NUMBER_OF_BEDROOMS' })}</strong>
          </P>
          <Input
            type="radio"
            name="division-bedrooms"
            value={undefined}
            checked={form.bedrooms === undefined}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            {intl.formatMessage({ id: 'ALL' })}
          </Input>
          <Input
            type="radio"
            name="division-bedrooms"
            value={1}
            checked={form.bedrooms === '1'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            1+ {intl.formatMessage({ id: 'ROOMS' })}
          </Input>
          <Input
            type="radio"
            name="division-bedrooms"
            value={2}
            checked={form.bedrooms === '2'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            2+ {intl.formatMessage({ id: 'ROOMS' })}
          </Input>
          <Input
            type="radio"
            name="division-bedrooms"
            value={3}
            checked={form.bedrooms === '3'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            3+ {intl.formatMessage({ id: 'ROOMS' })}
          </Input>
          <Input
            type="radio"
            name="division-bedrooms"
            value={4}
            checked={form.bedrooms === '4'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            4+ {intl.formatMessage({ id: 'ROOMS' })}
          </Input>
          <Input
            type="radio"
            name="division-bedrooms"
            value={5}
            checked={form.bedrooms === '5'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            5+ {intl.formatMessage({ id: 'ROOMS' })}
          </Input>
        </Form>
        <Form className="filter-spacing">
          <P>
            <strong>{intl.formatMessage({ id: 'LIVING_AREA' })}</strong>
          </P>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={undefined}
            checked={form.surfaceArea === undefined}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            {intl.formatMessage({ id: 'ALL' })}
          </Input>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={50}
            checked={form.surfaceArea === '50'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            50+ m2
          </Input>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={75}
            checked={form.surfaceArea === '75'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            75+ m2
          </Input>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={100}
            checked={form.surfaceArea === '100'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            100+ m2
          </Input>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={150}
            checked={form.surfaceArea === '150'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            150+ m2
          </Input>
          <Input
            type="radio"
            name="division-surfaceArea"
            value={200}
            checked={form.surfaceArea === '200'}
            onChangeRadio={e => this.handleRadioChangeWithMin(e)}
          >
            200+ m2
          </Input>
        </Form>
        <Form className="filter-spacing">
          <P>
            <strong>{intl.formatMessage({ id: 'OUTDOOR_SPACE' })}</strong>
          </P>
          <Input
            id="garden"
            type="checkbox"
            name="outdoorSpace-gardenFacilities"
            value="BACKYARD"
            checked={form.gardenFacilities.BACKYARD === true}
            onChangeCheckbox={e => this.handleCheckboxChange(e)}
          >
            {intl.formatMessage({ id: 'GARDEN' })}
          </Input>
          <Input
            id="balcony"
            type="checkbox"
            name="outdoorSpace-balcony"
            value="BALCONY"
            checked={form.balcony.BALCONY === true}
            onChangeCheckbox={e => this.handleCheckboxChange(e)}
          >
            {intl.formatMessage({ id: 'BALCONY' })}
          </Input>
          <Input
            id="roof-terrace"
            type="checkbox"
            name="outdoorSpace-balcony"
            value="BALCONY_ROOF_TERRACE"
            checked={form.balcony.BALCONY_ROOF_TERRACE === true}
            onChangeCheckbox={e => this.handleCheckboxChange(e)}
          >
            {intl.formatMessage({ id: 'BALCONY_ROOF_TERRACE' })}
          </Input>
        </Form>
        <Form className="filter-spacing">
          <P>
            <strong>{intl.formatMessage({ id: 'AVAILABILITY' })}</strong>
          </P>
          <Input
            type="checkbox"
            name="transfer-availabilty"
            value="AVAILABLE"
            checked={form.availabilty.AVAILABLE === true}
            onChangeCheckbox={e => this.handleCheckboxChange(e)}
          >
            {intl.formatMessage({
              id: 'SHOW_ALL_AVAILABLE_PROPERTIES',
            })}
          </Input>
        </Form>
      </>
    )

    return (
      <>
        <Layout>
          <DrawerWrapper
            className="d-lg-none"
            customBurgerIcon={false}
            customCrossIcon={false}
          >
            <Drawer
              isOpen={isDrawerOpen}
              onStateChange={state => this.isMenuOpen(state)}
              width={320}
            >
              <FilterWrapper>
                {filterMenu}
                <div
                  className="close-btn"
                  onClick={() => this.handleDrawer()}
                  onKeyDown={() => this.handleDrawer()}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="cross" width="32" />
                </div>
              </FilterWrapper>
            </Drawer>
          </DrawerWrapper>
          <SEO title={`${intl.formatMessage({ id: 'site_title' })} - ${intl.formatMessage({ id: 'header_3' })}`} />
          <TemplateWrapper>
            <Section>
              <Container className="d-flex">
                <H1>{intl.formatMessage({ id: 'header_3' })}</H1>
              </Container>
            </Section>
            <Section>
              <Container>
                <Row>
                  <Col xs={12} lg={3} className="d-none d-lg-block">
                    {filterMenu}
                  </Col>
                  <Col md={12} lg={9}>
                    <div className="d-lg-none">
                      <ButtonDrawer
                        onClick={() => this.handleDrawer()}
                        onKeyDown={() => this.handleDrawer()}
                        role="button"
                        tabIndex={0}
                      >
                        <Icon name="filter" width="24" />
                        <p>
                          <strong>
                            {intl.formatMessage({ id: 'FILTER_OFFER' })}
                          </strong>
                        </p>
                      </ButtonDrawer>
                    </div>

                    <ObjectLoadMoreWrapper data={result} />
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section>
              <Container>
                <ContactMePersonal
                  header={intl.formatMessage({ id: 'contact.title' })}
                  subTitle={intl.formatMessage({
                    id: 'contact.SUB_TITLE_AMBER',
                  })}
                  contactUs={intl.formatMessage({
                    id: 'CONTACT_US',
                  })}
                />
              </Container>
            </Section>
            <Footer/>
          </TemplateWrapper>
        </Layout>
      </>
    )
  }
}

export default injectIntl(ObjectOverview)
