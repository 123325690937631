import React from 'react'
import styled from 'styled-components'
import Icon from '../../../Icons'

const Label = styled.label`
  color: #888888;
  font-family: 'Montserrat';
  font-weight: 300;
  display: relative;
`

const Wrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 154px;
    pointer-events: none;
  }
`

const Select = styled.select`
  color: white;
  font-family: 'Montserrat';
  font-weight: 300;
  position: relative;
  display: block;
  min-width: 184px;
  min-height: 48px;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background: ${props => props.theme['$gray-light']};
  padding-left: 12px;
  cursor: pointer;
`

const Dropdown = ({ children, label, id, name, value, onOptionChange }) => (
  <>
    <Label htmlFor={id}>{label}</Label>
    <Wrapper>
      <Select onChange={onOptionChange} name={name} value={value} id={id}>
        {children}
      </Select>
      <Icon width="16" height="16" name="chevron" />
    </Wrapper>
  </>
)

export default Dropdown
