import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import Button from '../Buttons'
import { H2, H6 } from '../Typography'
import OutboundLink from '../Link/OutboundLink'
import ContactMePersonalImage from './ContactMePersonalImage'
import breakpoints from '../../util/style/breakpoints'

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme['$brand-primary']};
  margin-top: 3rem;
  padding: 2rem 1rem 2.875rem 1rem;

  ${breakpoints.md`
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  `};

  ${breakpoints.lg`
    padding: 4rem 3.5rem 3.5rem 3.5rem;
  `};
`
const PhoneLink = styled(OutboundLink)`
  color: ${(props) => props.theme['$btn-secondary-color']};
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  &:hover {
    color: #ffffff;
  }
`

const ContactMePersonal = ({ header, subTitle, contactUs }) => {
  return (
    <Wrapper>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="6">
            <Row className="mb-4 mb-md-5">
              <H2 className="mb-4">{header}</H2>
              <H6>{subTitle}</H6>
            </Row>

            <Row className="align-items-center justify-content-center justify-content-md-start">
              <Button
                name="primary-inverse"
                type="internal-link"
                color="#fff"
                to={`/contact`}
                from={header}
              >
                {contactUs}
              </Button>
            </Row>
          </Col>
          <Col xs={0} md={2} lg={3} />
          <Col xs={0} md={4} lg={3} className="d-none d-md-flex">
            <ContactMePersonalImage />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

ContactMePersonal.propTypes = {
  header: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default ContactMePersonal
