import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Row } from '@bootstrap-styled/v4'
import breakpoints from '../../util/style/breakpoints'

const Wrapper = styled.div`
  .object-col-md-padding {
    ${breakpoints.md`
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
  `};
  }
`

const ObjectRow = styled(Row)`
  margin-bottom: -4.5rem;
  ${breakpoints.md`
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  `};
`

const ObjectPreviewCardWrapper = ({ children }) => {
  return (
    <Wrapper>
      <ObjectRow className="justify-content-start">{children}</ObjectRow>
    </Wrapper>
  )
}

ObjectPreviewCardWrapper.propTypes = {}

export default injectIntl(ObjectPreviewCardWrapper)
