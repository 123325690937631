import React from 'react'
import PropTypes from 'prop-types'

import InputRadio from './InputRadio'
import InputCheckbox from './InputCheckbox'
import Dropdown from './Dropdown'

const Input = props => {
  switch (props.type) {
    case 'radio':
      return <InputRadio {...props} />
    case 'checkbox':
      return <InputCheckbox {...props} />
    case 'dropdown':
      return <Dropdown {...props} />
    default:
      return <div />
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Input
