import React, { useState, useEffect } from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Row, Col } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import ObjectPreviewCardWrapper from '../../ObjectPreviewCard/wrapper'
import ObjectPreviewCard from '../../ObjectPreviewCard'
import { slugify } from '../../../util/js/slugify'
import Button from '../../Buttons'

const ButtonWrapper = styled.div`
  margin-top: 4.5rem;
`

function LoadMoreObjects({ edges, intl }) {
  // State
  const [hasMore, setMore] = useState(edges.length > 6)
  const [currentList, addToList] = useState([...edges.slice(0, 6)])

  useEffect(() => {
    setMore(edges.length > 6)
    addToList([...edges.slice(0, 6)])
  }, [edges])

  const loadEdges = () => {
    const currentLength = currentList.length
    const more = currentLength < edges.length
    const nextEdges = more ? edges.slice(currentLength, currentLength + 6) : []
    setMore(more)
    addToList([...currentList, ...nextEdges])
  }

  const loadMore = () => {
    if (!hasMore) return
    loadEdges()
  }

  return (
    <>
      <ObjectPreviewCardWrapper>
        {currentList.length <= 0 && (
          <Container>
            <Row>
              <Col className="object-col-md-padding mb-5">
                <p>Geen objecten gevonden</p>
              </Col>
            </Row>
          </Container>
        )}
        {currentList.map(({ node }) => {
          return (
            <ObjectPreviewCard
              key={`properties-${node.id}`}
              url={`/${slugify(node.transfer.cityArea.city.cityName)}/${slugify(
                node.transfer.street
              )}/${node.id.substring(0, 6)}`}
              rentOrBuyProperty={node.rentOrBuyProperty}
              id={node.id}
              previewImage={
                node.realEstateImages &&
                node.realEstateImages[0] &&
                node.realEstateImages[0]._rawAsset
              }
              transfer={node.transfer}
              division={node.division}
              outdoorSpace={node.outdoorSpace}
            />
          )
        })}
      </ObjectPreviewCardWrapper>

      {hasMore && (
        <ButtonWrapper className="d-flex justify-content-center">
          <Button
            name="primary"
            type="button"
            className="mr-md-2"
            onClick={() => loadMore()}
          >
            {intl.formatMessage({ id: 'LOAD_MORE' })}
          </Button>
        </ButtonWrapper>
      )}
    </>
  )
}

function ObjectLoadMoreWrapper({ data, intl }) {
  return (
    <div>
      <LoadMoreObjects edges={data} intl={intl} />
    </div>
  )
}

export default injectIntl(ObjectLoadMoreWrapper)
